import * as React from "react";
import styled from "styled-components";

import colors from "../styles/colors";

const StyledTimeline = styled.div`
  display: inline-block;
  ul {
    padding: 10px 0 0 10px;
    margin: 0;
    list-style: none;
    position: relative;

    &::before {
      content: " ";
      height: 100%;
      width: 1px;
      background-color: ${colors.primary};
      position: absolute;
      top: 0;
      left: 17px;
      z-index: -1;
    }
  }

  li div {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: top;
  }
`;

const Timeline = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <StyledTimeline className={className}>
      <ul>{children}</ul>
    </StyledTimeline>
  );
};

const TimelineBullet = styled.div`
  width: 1em;
  height: 1em;
  box-sizing: border-box;
  border-radius: 50%;
  background: white;
  z-index: 1;
  margin: 4px 10px 10px 0;
  ${({ color = colors.primary }) => `
    border: 2px solid ${color};
  `}
`;

const TimelineDescription = styled.div`
  width: 50%;
`;

const TimelineHeading = styled.h3`
  font-size: 0.9em;
  font-weight: 400;
  margin: 0;
`;

const TimelineSubHeading = styled.h4`
  margin: 0;
  font-size: 0.7em;
  font-weight: 400;
  color: $dark-grey;
`;

const TimelineTime = styled.div`
  width: 140px;
  font-size: 0.75em;
  padding-top: 0.25em;
`;

Timeline.Entry = ({
  date,
  title,
  subtitle,
  secondary = false,
}: {
  date: Date;
  title: string;
  subtitle: string;
  secondary?: boolean;
}) => {
  const dateString = `${date.toLocaleString(undefined, {
    year: "numeric",
  })} ${date.toLocaleString(undefined, {
    month: "long",
  })}`;
  return (
    <li>
      <TimelineBullet color={secondary ? colors.secondary : colors.primary} />
      <TimelineTime>{dateString}</TimelineTime>
      <TimelineDescription>
        <TimelineHeading>{title}</TimelineHeading>
        <TimelineSubHeading>{subtitle}</TimelineSubHeading>
      </TimelineDescription>
    </li>
  );
};

export default Timeline;
