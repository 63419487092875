import * as React from "react";
import { Helmet } from "react-helmet";

export function Head() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta charSet="utf-8" />
      <meta
        name="description"
        content={`Sarah Hermanns, Resume, CV, Curriculum Vitae, Product Manager, Product Owner, Key Account Manager, Marketing Manager`}
      />
      <title>Sarah Hermanns</title>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}
