import * as React from "react";
import styled from "styled-components";

import "../styles/global.css";
import colors from "../styles/colors";
import profileImage from "../images/sarah-headshot.webp";
import Icon from "../components/Icon";
import Timeline from "../components/Timeline";
import { Head } from "../components/Head";

const maxContentWidth = "1000px;";

const Header = styled.header`
  background-color: ${colors.primary};
  color: white;
  padding: 10px;
`;

const HeaderContent = styled.div`
  max-width: ${maxContentWidth};
  margin: auto;
`;

const HeaderTextSection = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const SubHeader = styled.header`
  padding: 10px;
`;

const SocialMediaLogos = styled.div`
  display: flex;
  justify-content: center;
  a {
    margin: 0 5px;
  }
  svg {
    fill: ${colors.primary};
  }
`;

const IconLink = styled.a``;

const Page = styled.main`
  margin: 0 10px;
`;

const ProfileImage = styled.img`
  width: 300px;
  height: 310px;
  margin-right: headerPadding;
  border-radius: 50%;
  border: 4px solid white;
  margin: auto;
  display: block;
`;

const ContentBox = styled.div`
  color: black;
  max-width: ${maxContentWidth};
  margin: 10px auto;
`;

const ContentHeading = styled.h2`
  margin-top: 0;
`;

const TimelineContainer = styled.div``;

// markup
const IndexPage = () => {
  return (
    <>
      <Head />
      <Header>
        <HeaderContent>
          <ProfileImage alt="Sarah Hermanns Headshot" src={profileImage} />
          <HeaderTextSection>
            <h1>Sarah Hermanns</h1>
          </HeaderTextSection>
        </HeaderContent>
      </Header>
      <SubHeader>
        <HeaderContent>
          <SocialMediaLogos>
            <IconLink href="https://www.linkedin.com/in/sarah-hermanns/">
              <Icon
                type="linkedin"
                title="Link to Sarah Hermanns' LinkedIn profile"
              />
            </IconLink>
            <IconLink href="https://www.xing.com/profile/Sarah_Hermanns7/">
              <Icon type="xing" title="Link to Sarah Hermanns' Xing profile" />
            </IconLink>
          </SocialMediaLogos>
        </HeaderContent>
      </SubHeader>
      <Page>
        <ContentBox>
          <ContentHeading>Overview</ContentHeading>
          <p>
            With over 12 years experience, I have a range of skills all centred
            around pairing people and companies with the products and services
            that help them succeed. This ranges from involvement in the sales
            pipeline, all the way through to customer success including feeding
            back into the product lifecycle.
          </p>
        </ContentBox>
        <ContentBox>
          <ContentHeading>History</ContentHeading>
          <TimelineContainer>
            <Timeline>
              <Timeline.Entry
                date={new Date(2020, 8)}
                title="Key Account Manager"
                subtitle="Alnatura GmbH"
              />
              <Timeline.Entry
                date={new Date(2020, 4)}
                title="Brand Manager"
                subtitle="Corporate Management Solutions GmbH"
              />
              <Timeline.Entry
                date={new Date(2018, 10)}
                title="Service Manager"
                subtitle="Public Cafe Adelaide"
              />
              <Timeline.Entry
                date={new Date(2016, 8)}
                title="Global Key Account Specialist"
                subtitle="Merck KGaA"
              />
              <Timeline.Entry
                date={new Date(2013, 9)}
                title="Bachelor of Science - International Business Administration"
                subtitle="Hda Darmstadt"
                secondary
              />
              <Timeline.Entry
                date={new Date(2013, 5)}
                title="Global Product Manager"
                subtitle="Merck KGaA"
              />
              <Timeline.Entry
                date={new Date(2012, 5)}
                title="Procurement Manager"
                subtitle="Merck KGaA"
              />
              <Timeline.Entry
                date={new Date(2011, 8)}
                title="Assistant to the Management"
                subtitle="Merck Export GmbH"
              />
              <Timeline.Entry
                date={new Date(2009, 8)}
                title="Apprenticeship as Commercial Assistant"
                subtitle="Merck KGaA"
                secondary
              />
            </Timeline>
          </TimelineContainer>
        </ContentBox>
      </Page>
    </>
  );
};

export default IndexPage;
