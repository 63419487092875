import React from "react";

import LinkedIn from "./icons/LinkedIn";
import Print from "./icons/Print";
import Xing from "./icons/Xing";

export type IconOption = "linkedin" | "print" | "xing";

export interface IconProps {
  type: IconOption;
  title?: string;
}

export default function Icon({ type, title }: IconProps) {
  const props = { title };
  switch (type) {
    case "linkedin":
      return <LinkedIn {...props} />;
    case "print":
      return <Print {...props} />;
    case "xing":
      return <Xing {...props} />;
    default:
      return <Print {...props} />;
  }
}
